/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.v-carousel-all-view.scEnabledChrome .c-editorial-grid--item-content .editorial-item--image:before, .v-carousel-all-view.scEnabledChrome .c-editorial-grid--item-content .editorial-item--null:before,
.c-editorial-grid.scEnabledChrome .c-editorial-grid--item-content .editorial-item--image:before,
.c-editorial-grid.scEnabledChrome .c-editorial-grid--item-content .editorial-item--null:before {
  content: none;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme,
.c-editorial-grid.EditorialGrid_ContactUs_Theme {
  background-color: #000000;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .editorial-item--text-section,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .editorial-item--text-section {
  color: #ffffff;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .editorial-item--text-section .editorial-item--bodycopy,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .editorial-item--text-section .editorial-item--bodycopy {
  margin-bottom: 20px;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--middle,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--middle {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--middle,
  .c-editorial-grid.EditorialGrid_ContactUs_Theme .w--middle {
    margin-top: 40px;
    padding-bottom: 40px;
  }
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--middle .editorial-item--svg,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--middle .editorial-item--svg {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--content p.heading-7,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--content p.heading-7 {
  color: #ffec00;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before {
  content: "";
  display: block;
  border-left: 2px solid;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before {
  border-left-color: #e50000;
}
.v-carousel-all-view.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before,
.c-editorial-grid.EditorialGrid_ContactUs_Theme .w--content p.heading-7:before {
  margin-bottom: 20px;
  border-left-color: #f7f7f7;
}
.v-carousel-all-view .w--top .w--content,
.c-editorial-grid .w--top .w--content {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  margin: 0 auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .w--top .w--content,
  .c-editorial-grid .w--top .w--content {
    width: 63.9784946237%;
    max-width: 655.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .w--top .w--content,
  .c-editorial-grid .w--top .w--content {
    width: 47.9674796748%;
    max-width: 655.2357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
.v-carousel-all-view .prefixed-zero,
.c-editorial-grid .prefixed-zero {
  display: none;
}
.v-carousel-all-view--items,
.c-editorial-grid--items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.v-carousel-all-view--items .c-editorial-grid--item,
.c-editorial-grid--items .c-editorial-grid--item {
  width: 45.2380952381%;
  max-width: 347.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.v-carousel-all-view--items .c-editorial-grid--item:not(:last-child),
.c-editorial-grid--items .c-editorial-grid--item:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .v-carousel-all-view--items .c-editorial-grid--item,
  .c-editorial-grid--items .c-editorial-grid--item {
    width: 13.9784946237%;
    max-width: 143.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 15px;
  }
  .v-carousel-all-view--items .c-editorial-grid--item:not(:last-child),
  .c-editorial-grid--items .c-editorial-grid--item:not(:last-child) {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view--items .c-editorial-grid--item,
  .c-editorial-grid--items .c-editorial-grid--item {
    width: 14.6341463415%;
    max-width: 199.9024390244px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 15px;
  }
}
.v-carousel-all-view--items .c-editorial-grid--item .editorial-item,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item {
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-basis: auto;
  flex-basis: auto;
  display: block;
  flex: 1;
  list-style-type: none;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  /* Small devices (tablets, 768px and up) */
}
.v-carousel-all-view--items .c-editorial-grid--item .editorial-item--svg, .v-carousel-all-view--items .c-editorial-grid--item .editorial-item--image, .v-carousel-all-view--items .c-editorial-grid--item .editorial-item--null,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--svg,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--image,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--null {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 0 auto 10px;
  position: relative;
  float: none;
}
.v-carousel-all-view--items .c-editorial-grid--item .editorial-item--svg,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--svg {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .v-carousel-all-view--items .c-editorial-grid--item .editorial-item--image, .v-carousel-all-view--items .c-editorial-grid--item .editorial-item--null,
  .c-editorial-grid--items .c-editorial-grid--item .editorial-item--image,
  .c-editorial-grid--items .c-editorial-grid--item .editorial-item--null {
    margin-bottom: 19px;
  }
}
.v-carousel-all-view--items .c-editorial-grid--item .editorial-item--title,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--title {
  float: none;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}
.v-carousel-all-view--items .c-editorial-grid--item .editorial-item--bodycopy,
.c-editorial-grid--items .c-editorial-grid--item .editorial-item--bodycopy {
  float: none;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view--items .c-editorial-grid--item .editorial-item--bodycopy,
  .c-editorial-grid--items .c-editorial-grid--item .editorial-item--bodycopy {
    margin-bottom: 25px;
  }
}
.v-carousel-all-view .w--middle,
.c-editorial-grid .w--middle {
  margin-top: 30px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .w--middle,
  .c-editorial-grid .w--middle {
    margin-top: 39px;
  }
}
.v-carousel-all-view .w--middle .w--content,
.c-editorial-grid .w--middle .w--content {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  margin: 0 auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .w--middle .w--content,
  .c-editorial-grid .w--middle .w--content {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .w--middle .w--content,
  .c-editorial-grid .w--middle .w--content {
    width: 81.3008130081%;
    max-width: 1110.5691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view .w--middle .w--content,
  .c-editorial-grid .w--middle .w--content {
    width: 81.3008130081%;
    max-width: 1110.5691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
.v-carousel-all-view .w--top,
.c-editorial-grid .w--top {
  padding-top: 0;
}
.v-carousel-all-view .w--top img,
.c-editorial-grid .w--top img {
  max-width: 260px;
}
.v-carousel-all-view .w--top .heading-1,
.c-editorial-grid .w--top .heading-1 {
  margin-bottom: 0;
}
.v-carousel-all-view .w--top .body-copy-medium,
.v-carousel-all-view .w--top .body-copy-3,
.c-editorial-grid .w--top .body-copy-medium,
.c-editorial-grid .w--top .body-copy-3 {
  width: 100%;
  padding-top: 14px;
  margin-bottom: 12px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .w--top .body-copy-medium,
  .v-carousel-all-view .w--top .body-copy-3,
  .c-editorial-grid .w--top .body-copy-medium,
  .c-editorial-grid .w--top .body-copy-3 {
    padding-top: 24px;
    margin-bottom: 0;
  }
}
.v-carousel-all-view .w--top .short-title,
.v-carousel-all-view .w--top .body-copy-medium,
.c-editorial-grid .w--top .short-title,
.c-editorial-grid .w--top .body-copy-medium {
  padding-top: 16px;
}
.v-carousel-all-view .w--bottom,
.c-editorial-grid .w--bottom {
  width: 100%;
  margin-top: 0;
  display: block;
}
.v-carousel-all-view .w--bottom .w--content,
.v-carousel-all-view .w--bottom div.btn,
.c-editorial-grid .w--bottom .w--content,
.c-editorial-grid .w--bottom div.btn {
  margin: 0 auto;
  font-size: 0;
  line-height: 1.6;
}
.v-carousel-all-view .w--bottom a,
.c-editorial-grid .w--bottom a {
  margin: 0;
}
.v-carousel-all-view .slick-track .slick-slide,
.c-editorial-grid .slick-track .slick-slide {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: auto;
}
.v-carousel-all-view .slick-prev,
.v-carousel-all-view .slick-next,
.c-editorial-grid .slick-prev,
.c-editorial-grid .slick-next {
  top: 50%;
  border: none;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .slick-prev,
  .v-carousel-all-view .slick-next,
  .c-editorial-grid .slick-prev,
  .c-editorial-grid .slick-next {
    top: 25%;
    visibility: hidden;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .slick-prev,
  .v-carousel-all-view .slick-next,
  .c-editorial-grid .slick-prev,
  .c-editorial-grid .slick-next {
    top: 47%;
    visibility: visible;
    background-color: transparent;
  }
  .v-carousel-all-view .slick-prev:after,
  .v-carousel-all-view .slick-next:after,
  .c-editorial-grid .slick-prev:after,
  .c-editorial-grid .slick-next:after {
    border: 2px solid #ffec00;
    border-bottom: none;
    border-left: none;
    width: 30px;
    height: 30px;
  }
}
.v-carousel-all-view .slick-prev,
.c-editorial-grid .slick-prev {
  left: 15px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .slick-prev,
  .c-editorial-grid .slick-prev {
    left: -45px;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view .slick-prev,
  .c-editorial-grid .slick-prev {
    left: -50px;
  }
}
.v-carousel-all-view .slick-next,
.c-editorial-grid .slick-next {
  right: 15px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .slick-next,
  .c-editorial-grid .slick-next {
    right: -45px;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view .slick-next,
  .c-editorial-grid .slick-next {
    right: -50px;
  }
}
.v-carousel-all-view .c-editorial-grid--item,
.c-editorial-grid .c-editorial-grid--item {
  margin-bottom: 0;
}
.v-carousel-all-view .c-editorial-grid--item-content,
.c-editorial-grid .c-editorial-grid--item-content {
  margin-bottom: 10px;
  margin-top: 5px;
  /* Small devices (tablets, 768px and up) */
}
.v-carousel-all-view .c-editorial-grid--item-content:not(.dot-enabled) .custom-dots,
.c-editorial-grid .c-editorial-grid--item-content:not(.dot-enabled) .custom-dots {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .c-editorial-grid--item-content:not(.dot-enabled) .custom-dots,
  .c-editorial-grid .c-editorial-grid--item-content:not(.dot-enabled) .custom-dots {
    visibility: hidden;
    margin-top: 12px;
  }
}
@media (min-width: 768px) {
  .v-carousel-all-view .c-editorial-grid--item-content,
  .c-editorial-grid .c-editorial-grid--item-content {
    width: 33%;
    display: inline-block;
  }
  .v-carousel-all-view .c-editorial-grid--item-content:not(:last-child),
  .c-editorial-grid .c-editorial-grid--item-content:not(:last-child) {
    margin-bottom: 10px;
    margin-top: 5px;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item {
  background-color: #ffffff;
  border-radius: 4px;
  display: block;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
  margin: 0 5px;
  padding-bottom: 29px;
  /* Medium devices (desktops, 1024px and up) */
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item {
    margin: 0 14px;
    padding-left: 0;
    padding-right: 0;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null {
  border: 0;
  padding-top: 0;
  margin-bottom: 0;
  width: 100%;
  height: auto;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image img, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null img,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image img,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null img {
  margin: initial;
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  position: relative;
  padding-top: 27px;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--image,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--null {
    padding-top: 24px;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image:before, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null:before,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image:before,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null:before {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 19px;
  z-index: 1;
  content: "";
  width: 100%;
  height: 232px;
  background-position: center;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image picture, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null picture,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image picture,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null picture {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 191px;
  width: 191px;
  margin: 0 auto;
  z-index: 10;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--image picture img, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--null picture img,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--image picture img,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--null picture img {
  height: 100%;
  width: 100%;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--title, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--bodycopy, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--read-more,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--title,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--bodycopy,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--read-more {
  text-align: center;
  width: 100%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--title, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--bodycopy, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--read-more,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--title,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--bodycopy,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--read-more {
    padding: 0 30px;
    text-align: center;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--title,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--title {
  padding-top: 12px;
  margin-bottom: 0;
  text-align: center;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--title,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--title {
    padding-top: 14.3px;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--bodycopy,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--bodycopy {
  padding: 13px 20px 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--bodycopy,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--bodycopy {
    padding: 10px 17px 0 23px;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item--bodycopy,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item--bodycopy {
    padding-top: 9px;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item--read-more,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item--read-more {
  padding-top: 15px;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .right-arrow,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .right-arrow {
  margin-top: 0;
  position: relative;
  text-decoration: underline;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .right-arrow:hover, .v-carousel-all-view .c-editorial-grid--item-content .editorial-item .right-arrow:focus,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .right-arrow:hover,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .right-arrow:focus {
  text-decoration: none;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .right-arrow:after,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .right-arrow:after {
  top: 6px;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .ride-info-tile,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .ride-info-tile {
  margin: 0;
  width: 50%;
  display: inline-block;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .ride-info-tile img,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .ride-info-tile img {
  display: inline-block;
  margin: 0 4px;
  height: 35px;
  width: 35px;
  margin-bottom: 6px;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .ride-info-tile .body-3,
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .ride-info-tile .body-copy-3,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .ride-info-tile .body-3,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .ride-info-tile .body-copy-3 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item .item-rendered,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item .item-rendered {
  margin-top: 12px;
  margin-bottom: 1px;
  padding-bottom: 16px;
  text-align: center;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .c-editorial-grid--item-content .editorial-item .item-rendered,
  .c-editorial-grid .c-editorial-grid--item-content .editorial-item .item-rendered {
    margin-top: 15px;
  }
}
.v-carousel-all-view .c-editorial-grid--item-content .editorial-item.editorial-item-icons,
.c-editorial-grid .c-editorial-grid--item-content .editorial-item.editorial-item-icons {
  padding-bottom: 0px;
}
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+1) .editorial-item--image:before,
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+1) .editorial-item--null:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+1) .editorial-item--image:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+1) .editorial-item--null:before {
  transform: rotate(180deg);
  left: 13px;
  top: -24px;
}
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+2) .editorial-item--image:before,
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+2) .editorial-item--null:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+2) .editorial-item--image:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+2) .editorial-item--null:before {
  left: -17px;
  top: 14px;
  transform: none;
}
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+3) .editorial-item--image:before,
.v-carousel-all-view .c-editorial-grid--item-content:nth-child(3n+3) .editorial-item--null:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+3) .editorial-item--image:before,
.c-editorial-grid .c-editorial-grid--item-content:nth-child(3n+3) .editorial-item--null:before {
  transform: scaleX(-1) rotate(242deg);
  left: -17px;
  top: 14px;
}

.v-carousel-all-view {
  /* Small devices (tablets, 768px and up) */
}
.v-carousel-all-view .dot-enabled .custom-dots {
  height: auto;
  /* Small devices (tablets, 768px and up) */
  margin: 0;
}
@media (min-width: 768px) {
  .v-carousel-all-view .dot-enabled .custom-dots {
    position: static;
  }
}
.v-carousel-all-view .dot-enabled .custom-dots li {
  margin: 19px 0 0;
}
.v-carousel-all-view .dot-enabled .custom-dots li span:before,
.v-carousel-all-view .dot-enabled .custom-dots li button:before {
  opacity: 1;
  border: none;
}
.v-carousel-all-view .dot-enabled .custom-dots li.slick-active {
  margin: 15px 0 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view .dot-enabled .custom-dots li.slick-active {
    margin: 30px 0 0;
  }
}
.v-carousel-all-view .dot-enabled .custom-dots li.slick-active span:before,
.v-carousel-all-view .dot-enabled .custom-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  top: -2px;
}
.v-carousel-all-view .custom-dots {
  bottom: -45px;
}
.v-carousel-all-view.component {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .v-carousel-all-view.component {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .v-carousel-all-view .dot-enabled .custom-dots li span span,
  .v-carousel-all-view .dot-enabled .custom-dots li button span {
    display: none;
  }
}
.v-carousel-all-view.content-carousel {
  margin-top: 0;
  /* Medium devices (desktops, 1024px and up) */
}
.v-carousel-all-view.content-carousel.component {
  margin-bottom: 0;
}
.v-carousel-all-view.content-carousel.component .editorial-item--text-section {
  /* Medium devices (desktops, 1024px and up) */
}
.v-carousel-all-view.content-carousel.component .editorial-item--text-section a,
.v-carousel-all-view.content-carousel.component .editorial-item--text-section a:hover {
  color: #fff;
  text-decoration: none;
  display: block;
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel.component .editorial-item--text-section {
    margin-top: 2px;
  }
}
.v-carousel-all-view.content-carousel.component .editorial-item--text-section .editorial-item--read-more a {
  display: inline-block;
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots {
  padding-bottom: 40px;
  /* Small devices (tablets, 768px and up) */
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li {
  display: inline-block;
  margin: 0;
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li.slick-active span:before,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li.slick-active button:before {
  background-color: #ffffff;
  opacity: 1;
  top: 0;
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li span,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li button {
  letter-spacing: 0;
  font-size: 0;
  line-height: 0;
  padding: 3px 10px;
  cursor: pointer;
  color: transparent;
  border: 0;
  background: transparent;
  position: relative;
  margin: 0 5px;
  left: -5px;
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li span:before,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li button:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 6px;
  border-radius: 0;
  background-color: #ffffff;
  opacity: 0.35;
  transform: skew(-20deg);
  top: 0;
  left: 0px;
}
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li span span,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li span button,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li button span,
.v-carousel-all-view.content-carousel .dot-enabled .custom-dots li button button {
  display: none;
}
@media (min-width: 768px) {
  .v-carousel-all-view.content-carousel .dot-enabled .custom-dots li,
  .v-carousel-all-view.content-carousel .dot-enabled .custom-dots li.slick-active {
    margin: 0;
  }
}
.v-carousel-all-view.content-carousel .w--top:before {
  content: "";
  display: block;
  border-left: 2px solid;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.v-carousel-all-view.content-carousel .w--top:before {
  border-left-color: #e50000;
}
.v-carousel-all-view.content-carousel .w--top::before {
  border-left: 1px solid #e50000;
}
.v-carousel-all-view.content-carousel .w--top .heading-1 {
  color: #000000;
}
.v-carousel-all-view.content-carousel .w--top .body-copy-3 {
  color: #ffffff;
  padding-top: 14px;
  margin: 0 auto;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel .w--top .body-copy-3 {
    padding-bottom: 34px;
  }
}
.v-carousel-all-view.content-carousel .w--top.w--content .w--content .body-copy-3 {
  color: #333333;
}
.v-carousel-all-view.content-carousel .w--middle {
  margin-top: 12px;
}
.v-carousel-all-view.content-carousel .w--middle .w--content {
  width: 100%;
  margin: 0 auto;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel .w--middle .w--content {
    width: 86.3008130081%;
    max-width: 1178.8691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view.content-carousel .w--middle .w--content {
    width: 89.6341463415%;
    max-width: 1224.4024390244px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
.v-carousel-all-view.content-carousel .w--middle .w--content .v-carousel-view-container.clearfix .editorial-item .editorial-item--text-section .editorial-item--bodycopy ul li {
  text-align: left;
  margin-left: 20px;
}
.lang-ar .v-carousel-all-view.content-carousel .w--middle .w--content .v-carousel-view-container.clearfix .editorial-item .editorial-item--text-section .editorial-item--bodycopy ul li {
  direction: rtl;
  text-align: right;
  margin-right: 20px;
}
.v-carousel-all-view.content-carousel .w--middle .w--content .v-carousel-view-container.clearfix::before, .v-carousel-all-view.content-carousel .w--middle .w--content .v-carousel-view-container.clearfix::after {
  content: none;
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item {
  background: transparent;
  box-shadow: none;
  color: #ffffff;
  padding-bottom: 24px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item {
    margin: 0 6px;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item {
    margin: 0 11px;
  }
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--image {
  padding-top: 0;
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--image picture {
  border-radius: 0;
  width: auto;
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--image img {
  border-radius: 0;
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--title {
  padding-top: 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--title {
    margin-top: 28px;
    padding-top: 0px;
  }
}
.v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--bodycopy {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--bodycopy {
    padding: 15px 21px 0;
  }
}
@media only screen and (min-width: 1366px) {
  .v-carousel-all-view.content-carousel .c-editorial-grid--item-content .editorial-item--bodycopy {
    padding: 15px 31px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .v-carousel-all-view.content-carousel .w--middle {
    position: relative;
    max-width: 1366px;
    margin: auto;
    margin-top: 26px;
  }
  .v-carousel-all-view.content-carousel .slick-prev,
  .v-carousel-all-view.content-carousel .slick-next {
    top: 166px;
  }
  .v-carousel-all-view.content-carousel .slick-next {
    right: -70px;
  }
  .v-carousel-all-view.content-carousel .slick-prev {
    left: -70px;
  }
}
.v-carousel-all-view.content-carousel .slick-prev,
.v-carousel-all-view.content-carousel .slick-next {
  width: 60px;
  height: 60px;
  background: #000000;
  border-radius: 0;
  background-size: 100%;
}
.v-carousel-all-view.content-carousel .slick-prev:after,
.v-carousel-all-view.content-carousel .slick-next:after {
  display: none;
}
.zoom-in .v-carousel-all-view.content-carousel .slick-prev,
.zoom-in .v-carousel-all-view.content-carousel .slick-next {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .zoom-in .v-carousel-all-view.content-carousel .slick-prev,
  .zoom-in .v-carousel-all-view.content-carousel .slick-next {
    top: 25%;
  }
}

/* Medium devices (desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  body:not(.lang-ar) .c-editorial-grid .body-copy-3 {
    margin-left: auto;
  }
}
/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .park-overview-page .v-carousel-all-view .dot-enabled .custom-dots {
    margin-bottom: 50px;
  }
}
.editorial-grid-extn {
  background: #f7f7f7;
  padding: 60px 0 20px;
}
.editorial-grid-extn.component {
  margin-bottom: 0px;
}
.editorial-grid-extn.component .w--content {
  margin-bottom: 0;
}
.editorial-grid-extn .w--middle {
  margin-top: 37px;
  background: none;
}
.editorial-grid-extn .w--middle::after {
  display: none;
}
.editorial-grid-extn .w--middle .w--content {
  width: 100%;
  max-width: 100%;
}
.editorial-grid-extn .w--top .w--content {
  width: 100%;
  max-width: 100%;
  margin: 0;
}
.editorial-grid-extn .w--top .heading-1 {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .editorial-grid-extn .w--top .heading-1 {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .editorial-grid-extn .w--top .heading-1 {
    width: 82.9674796748%;
    max-width: 1133.3357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .editorial-grid-extn .w--top .heading-1 {
    width: 62.1341463415%;
    max-width: 848.7524390244px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
.editorial-grid-extn .heading-1 {
  position: relative;
  text-align: center;
}
.editorial-grid-extn .heading-1:before {
  content: " ";
  width: 100%;
  height: 2px;
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 50%;
  display: block;
}
@media (min-width: 768px) {
  .editorial-grid-extn .heading-1:before {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
    margin: 0 auto;
  }
}
.editorial-grid-extn .heading-1 p {
  display: inline-block;
  background: #f7f7f7;
  padding: 0 10px;
  position: relative;
  color: #000000;
}
.editorial-grid-extn .heading-1 + .body-copy-3 {
  margin-top: 7px;
  /* Small devices (tablets, 768px and up) */
  word-wrap: break-word;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .editorial-grid-extn .heading-1 + .body-copy-3 {
    margin-top: 13px;
  }
}
.editorial-grid-extn .c-editorial-grid--item {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .editorial-grid-extn .c-editorial-grid--item {
    width: 47.311827957%;
    max-width: 484.4731182796px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .editorial-grid-extn .c-editorial-grid--item {
    width: 47.9674796748%;
    max-width: 655.2357723577px;
    padding-left: 1.0162601626%;
    padding-right: 1.0162601626%;
    margin: 0;
  }
}
.editorial-grid-extn .c-editorial-grid--item:not(:last-child) {
  margin-bottom: auto;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .editorial-grid-extn .c-editorial-grid--item:not(:last-child) {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .editorial-grid-extn .c-editorial-grid--item:last-child {
    padding-right: 0;
  }
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item {
  background-color: transparent;
  padding-bottom: 0;
  text-align: left;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--title, .editorial-grid-extn .c-editorial-grid--item .editorial-item--image, .editorial-grid-extn .c-editorial-grid--item .editorial-item--bodycopy {
  float: none;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--bodycopy, .editorial-grid-extn .c-editorial-grid--item .editorial-item--title, .editorial-grid-extn .c-editorial-grid--item .editorial-item--read-more {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--bodycopy {
  color: #515151;
  margin-bottom: 30px;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--title {
  padding-top: 0;
  margin-bottom: 20px;
  color: #000000;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--image {
  width: 100%;
  height: 100%;
  min-height: auto;
  margin-bottom: 26px;
}
.editorial-grid-extn .c-editorial-grid--item .editorial-item--image img {
  width: 100%;
  margin-bottom: 0;
}

.confirmation-overlay.overlay .overlay-container {
  height: 215px;
  position: relative;
  top: 30%;
}
.confirmation-overlay.overlay .overlay-container .overlay-content-inner-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  overflow-y: unset;
}
.confirmation-overlay.register-success-overlay .user-main-wrapper {
  width: auto;
}
.confirmation-overlay.register-success-overlay .user-main-wrapper span {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
}
.confirmation-overlay.register-failure-overlay .overlay-content-inner-container {
  color: #404040;
}

.component.c-editorial-grid.error-component .w--bottom-err-component .btn-primary a {
  padding: 12px 0 0;
}

.c-editorial-grid.error-component .w--bottom-err-component .btn-primary a {
  padding: 0;
  padding-top: 12px;
  width: 135px;
  height: 45px;
}
.c-editorial-grid.error-component .w--top .heading-1 {
  line-height: normal;
  font-size: 22px;
  font-size: 1.375rem;
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-editorial-grid.error-component .w--top .heading-1 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.c-editorial-grid.error-component .w--top p.dynamic-err-message {
  padding-top: 0;
  margin-top: 14px;
  /* Medium devices (desktops, 1024px and up) */
  font-size: 14px;
  font-size: 0.875rem;
}
@media only screen and (min-width: 1024px) {
  .c-editorial-grid.error-component .w--top p.dynamic-err-message {
    margin-top: 17px;
  }
}
.c-editorial-grid.error-component .err-image-container {
  /* Medium devices (desktops, 1024px and up) */
  /* < 768px */
}
@media only screen and (min-width: 1024px) {
  .c-editorial-grid.error-component .err-image-container {
    padding: 90px 0 10px 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-editorial-grid.error-component .err-image-container {
    padding: 62px 0 15px 0;
  }
}
.c-editorial-grid.error-component .w--content .heading-1 {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-editorial-grid.error-component .w--content .heading-1 {
    line-height: 25px;
  }
}

.c-editorial-grid-tooltip .product-tooltip {
  display: inline-block;
  position: relative;
  margin-left: 0.25rem;
}
.c-editorial-grid-tooltip .product-tooltip ul {
  text-align: left;
  margin-left: 18px;
}
.c-editorial-grid-tooltip .product-tooltip-icon {
  background-color: #f7f7f7;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 10px;
  height: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  text-decoration: none;
  width: 0.75rem;
}
.c-editorial-grid-tooltip .product-tooltip-body {
  background-color: #ffffff;
  border: solid 1px #d3d3d3;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 25px;
  position: absolute;
  top: 20px;
  transform: translateX(-40%);
  width: 16.375rem;
  z-index: 5;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-editorial-grid-tooltip .product-tooltip-body {
    width: 17.375rem;
  }
}
.c-editorial-grid-tooltip .product-tooltip-body:before {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-width: 1px 0 0 1px;
  content: "";
  display: block;
  height: 10px;
  left: 47%;
  position: absolute;
  top: -6px;
  transform: rotateZ(45deg);
  width: 0.625rem;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-editorial-grid-tooltip .product-tooltip-body:before {
    left: 44%;
  }
}
.c-editorial-grid-tooltip .product-tooltip-body .short-title {
  text-align: center;
  float: none;
}
.c-editorial-grid-tooltip .product-tooltip svg {
  width: 13px;
  height: 13px;
}
.c-editorial-grid-tooltip .product-tooltip .editorial-grid-tile-icon {
  cursor: pointer;
}

.editorial-grid-tooltip-wrapper {
  max-width: 1920px;
}

.c-hero-component .editorial-grid-icon,
.editorial-grid-tooltip-wrapper .editorial-grid-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.c-hero-component .c-editorial-grid-tooltip,
.editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip {
  width: 45.2380952381%;
  max-width: 347.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  text-align: center;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip:not(:last-child),
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip:not(:last-child) {
    border-right: 1px dotted #d3d3d3;
  }
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body,
.editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body {
  text-align: initial;
  top: 140px;
  left: 53%;
  padding: 15px;
  width: 196px;
  transform: translateX(-48%);
  z-index: 1;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:before,
.editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body:before {
  left: 45%;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:after,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body:after {
    content: "";
    display: block;
    height: 15px;
    width: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body {
    width: 260px;
    transform: translateX(-51%);
  }
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body img,
.editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body img {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body {
    transform: translateX(-50%);
  }
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:before,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip .product-tooltip-body:before {
    left: 48%;
  }
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-grid-tooltip,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip {
    width: 26.4784946237%;
    max-width: 271.1397849462px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip,
  .editorial-grid-tooltip-wrapper .c-editorial-grid-tooltip {
    width: 22.9674796748%;
    max-width: 313.7357723577px;
    padding-left: 1.0162601626%;
    padding-right: 1.0162601626%;
  }
}
.c-hero-component .short-title,
.editorial-grid-tooltip-wrapper .short-title {
  display: block;
  padding-top: 24px;
  color: #333333;
}
.c-hero-component .short-title .body-copy-3,
.editorial-grid-tooltip-wrapper .short-title .body-copy-3 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: center;
}
.c-hero-component .short-title .body-copy-4,
.editorial-grid-tooltip-wrapper .short-title .body-copy-4 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
}
.c-hero-component .product-tooltip,
.editorial-grid-tooltip-wrapper .product-tooltip {
  margin: 0;
}
.c-hero-component .product-tooltip:after, .c-hero-component .product-tooltip:before,
.editorial-grid-tooltip-wrapper .product-tooltip:after,
.editorial-grid-tooltip-wrapper .product-tooltip:before {
  content: none;
}
.c-hero-component .product-tooltip .close-tooltip,
.editorial-grid-tooltip-wrapper .product-tooltip .close-tooltip {
  position: absolute;
  top: 7px;
  right: 9px;
  cursor: pointer;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .close-tooltip,
  .editorial-grid-tooltip-wrapper .product-tooltip .close-tooltip {
    display: none;
  }
}
.c-hero-component .product-tooltip .close-tooltip svg.icn-close,
.editorial-grid-tooltip-wrapper .product-tooltip .close-tooltip svg.icn-close {
  width: 10px;
  height: 10px;
}
.c-hero-component .product-tooltip .body-copy-4,
.editorial-grid-tooltip-wrapper .product-tooltip .body-copy-4 {
  color: #e50000;
  text-align: initial;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .body-copy-4,
  .editorial-grid-tooltip-wrapper .product-tooltip .body-copy-4 {
    top: 16%;
  }
}
.c-hero-component .product-tooltip .header-bottom-profile-name,
.editorial-grid-tooltip-wrapper .product-tooltip .header-bottom-profile-name {
  margin-top: 8px;
  padding: 0;
  text-align: initial;
  color: #333333;
}
.c-hero-component .product-tooltip .editorial-grid-tile-icon,
.editorial-grid-tooltip-wrapper .product-tooltip .editorial-grid-tile-icon {
  width: 60px;
}
.c-hero-component .editorial-grid-tooltip-wrapper,
.editorial-grid-tooltip-wrapper .editorial-grid-tooltip-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-hero-component .editorial-grid-tooltip-wrapper img,
.editorial-grid-tooltip-wrapper .editorial-grid-tooltip-wrapper img {
  width: 60px;
}
.c-hero-component .editorial-grid-tooltip-wrapper .more-content-hidden,
.editorial-grid-tooltip-wrapper .editorial-grid-tooltip-wrapper .more-content-hidden {
  display: none;
}
.c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible,
.editorial-grid-tooltip-wrapper .editorial-grid-tooltip-wrapper .more-content-visible {
  display: block;
  text-align: center;
  margin-top: 30px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible,
  .editorial-grid-tooltip-wrapper .editorial-grid-tooltip-wrapper .more-content-visible {
    display: none;
  }
}
.c-hero-component .editorial-grid-icon,
.editorial-grid-tooltip-wrapper .editorial-grid-icon {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-grid-icon,
  .editorial-grid-tooltip-wrapper .editorial-grid-icon {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-grid-icon,
  .editorial-grid-tooltip-wrapper .editorial-grid-icon {
    width: 81.3008130081%;
    max-width: 1110.5691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 auto;
  }
}

.contact-us .c-editorial-grid .w--top,
.contact-us .c-editorial-grid .w--bottom {
  display: none;
}
.contact-us .c-editorial-grid .w--middle {
  background-color: #000000;
  position: relative;
  margin-top: 16px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  background-color: #ffffff;
  margin-top: 0;
  /* Small devices (tablets, 768px and up) */
  background-color: #e50000;
  margin-top: 0;
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid .w--middle {
    margin-top: 26px;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid .w--middle {
    margin-top: 34px;
  }
}
.contact-us .c-editorial-grid .w--middle:before {
  background: url("../../../assets/img/ZigZag-pattern/zig-zag-dark-black@2x.png") no-repeat center center;
  content: "";
  display: block;
  height: 16px;
  top: -16px;
  position: absolute;
  width: 100%;
  max-width: 768px;
  background-size: 904px 16px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid .w--middle:before {
    top: -26px;
    height: 26px;
    background-size: 1468px 26px;
    max-width: none;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid .w--middle:before {
    top: -34px;
    height: 34px;
    background-size: 1920px 34px;
    max-width: none;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid .w--middle:before {
    max-width: none;
  }
}
@media only screen and (min-width: 1920px) {
  .contact-us .c-editorial-grid .w--middle:before {
    height: 35px;
    background-size: 100% 100%;
  }
}
.contact-us .c-editorial-grid .w--middle:after {
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
  max-width: none;
  background-repeat: no-repeat;
  background-position: center center;
  bottom: 0;
  height: 16px;
  background-size: 904px 16px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid .w--middle:after {
    height: 26px;
    background-size: 1468px 26px;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid .w--middle:after {
    height: 34px;
    background-size: 1920px 34px;
    max-width: none;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid .w--middle:after {
    max-width: none;
  }
}
@media only screen and (min-width: 1920px) {
  .contact-us .c-editorial-grid .w--middle:after {
    height: 35px;
    background-size: 100% 100%;
  }
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid .w--middle {
    margin-top: 0;
  }
}
.contact-us .c-editorial-grid .w--middle:before {
  content: none;
}
.contact-us .c-editorial-grid .w--middle:after {
  background-image: url("../../../assets/img/ZigZag-pattern/zig-zag-white@2x.png");
  content: "";
}
.contact-us .c-editorial-grid .w--middle .w--content {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid .w--middle .w--content {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid .w--middle .w--content {
    width: 64.6341463415%;
    max-width: 882.9024390244px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid .w--middle .w--content {
    width: 64.8484848485%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.contact-us .c-editorial-grid img {
  margin-bottom: 0;
  width: auto !important;
}
.contact-us .c-editorial-grid--items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 43px 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 78px 0 83px;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid--items {
    padding: 70px 0 83px;
  }
}
.contact-us .c-editorial-grid--items .c-editorial-grid--item {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item {
    width: 47.311827957%;
    max-width: 484.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0;
    position: relative;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item {
    width: 47.9674796748%;
    max-width: 655.2357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item {
    width: 48.1818181818%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
  }
}
.contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--image {
  width: 28.5714285714%;
  max-width: 219.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  height: auto;
  margin-bottom: 20px;
  min-height: 100px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--image {
    width: 47.311827957%;
    max-width: 484.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--image {
    width: 47.9674796748%;
    max-width: 655.2357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--image {
    width: 48.1818181818%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
  }
}
.contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--text-section {
  width: 61.9047619048%;
  max-width: 475.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--text-section {
    width: 72.311827957%;
    max-width: 740.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--text-section {
    width: 72.9674796748%;
    max-width: 996.7357723577px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--text-section {
    width: 73.1818181818%;
    max-width: 1296px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--bodycopy {
  margin-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .c-editorial-grid--item .editorial-item--bodycopy {
    margin-bottom: 0;
  }
}
.contact-us .c-editorial-grid--items .c-editorial-grid--item:not(:last-child) {
  margin-bottom: 22px;
  padding-bottom: 0px;
}
.contact-us .c-editorial-grid--items .editorial-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .editorial-item {
    display: block;
  }
}
.contact-us .c-editorial-grid--items .editorial-item--text-section {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-align-self: flex-start;
  align-self: flex-start;
}
.contact-us .c-editorial-grid--items .editorial-item--title {
  float: none;
  text-align: left;
  margin-bottom: 10px;
  color: #ffffff;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .editorial-item--title {
    text-align: center;
    padding: 0;
  }
}
.contact-us .c-editorial-grid--items .editorial-item--bodycopy {
  text-align: left;
  color: #ffffff;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .contact-us .c-editorial-grid--items .editorial-item--bodycopy {
    text-align: center;
    padding: 0;
  }
}